import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue')
  },
  // 这里是产品路由
  {
    path: '/product',
    name: 'Product',
    component: () => import('../views/product.vue')
  },
  // 这里是行业路由
  {
    path: '/metalProcessing',
    name: 'MetalProcessing',
    component: () => import('../views/trade/metal-processing.vue')
  },
  {
    path: '/printing',
    name: 'Printing',
    component: () => import('../views/trade/printing.vue')
  },
  {
    path: '/autoParts',
    name: 'AutoParts',
    component: () => import('../views/trade/auto-parts.vue')
  },
  {
    path: '/electronic',
    name: 'Electronic',
    component: () => import('../views/trade/electronic.vue')
  },
  {
    path: '/newEnergy',
    name: 'NewEnergy',
    component: () => import('../views/trade/new-energy.vue')
  },
  {
    path: '/expressLogistics',
    name: 'ExpressLogistics',
    component: () => import('../views/trade/express-logistics.vue')
  },
  // 这里是资讯的路由
  {
    path: '/companyInformation',
    name: 'CompanyInformation',
    component: () => import('../views/information/company-information.vue')
  },
  {
    path: '/tradeInformation',
    name: 'TradeInformation',
    component: () => import('../views/information/trade-information.vue')
  },
  {
    path: '/detailInformation',
    name: 'DetailInformation',
    component: () => import('../views/information/detail-information.vue')
  },
  {
    path: '/detailInformation_',
    name: 'DetailInformation_',
    component: () => import('../views/information/detail-information_.vue')
  },
  // 404必须放在最后
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('../views/error.vue')
  }
]
const router = new VueRouter({
  routes
})

export default router
// 导航完成后会调用的函数
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
