<template>
  <v-app>
    <v-main>
      <section>
        <v-row no-gutters>
          <v-col cols="12">
            <v-navigation-drawer v-model="drawer" fixed temporary app style="z-index:999">
              <v-list>
                <v-list-item to="/">
                  <v-list-item-action>
                    <v-icon>mdi-home</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>首页</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/product">
                  <v-list-item-action>
                    <v-icon>mdi-diamond</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>产品</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-group prepend-icon="mdi-account-group" no-action>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>行业</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item v-for="(item,index) in tradeSubtitles" :key="index" :to="item.to">
                    <v-list-item-content>
                      <v-list-item-title>{{item.title}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>

                <v-list-group prepend-icon="mdi-message-text" no-action>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>资讯</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item v-for="(item,index) in newsSubtitles" :key="index" :to="item.to">
                    <v-list-item-content>
                      <v-list-item-title>{{item.title}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>

                <v-list-item to="/about">
                  <v-list-item-action>
                    <v-icon>mdi-information</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>关于</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

              </v-list>
            </v-navigation-drawer>

            <v-app-bar fixed app height="64" elevate-on-scroll color="white">
              <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="$vuetify.breakpoint.mobile"></v-app-bar-nav-icon>
              <div>
                <v-img src="../src/assets/home/云联图标.png" width="155"></v-img>
              </div>

              <v-spacer></v-spacer>

              <v-row justify="center" class="mr-16 pr-16" v-if="!$vuetify.breakpoint.mobile">
                <v-tabs slider-color="white">
                  <v-tab class="text-subtitle-1 mx-4" to="/">
                    首页
                  </v-tab>
                  <v-tab class="text-subtitle-1 mx-4" to="/product">
                    产品
                  </v-tab>

                  <v-menu open-on-hover bottom offset-y close-delay="10">
                    <template v-slot:activator="{ on, attrs }">
                      <v-tab color="primary" class="text-subtitle-1 mx-4" v-bind="attrs" v-on="on" to="/metalProcessing">
                        行业
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-tab>
                    </template>
                    <v-list>
                      <v-list-item v-for="(item, index) in tradeSubtitles" :key="index" :to="item.to">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <v-menu open-on-hover bottom offset-y close-delay="10">
                    <template v-slot:activator="{ on, attrs }">
                      <v-tab color="primary" class="text-subtitle-1 mx-4" v-bind="attrs" v-on="on" to="/companyInformation">
                        资讯
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-tab>
                    </template>
                    <v-list>
                      <v-list-item v-for="(item, index) in newsSubtitles" :key="index" :to="item.to">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <v-tab class="text-subtitle-1 mx-4" to="/about">
                    关于
                  </v-tab>
                </v-tabs>
              </v-row>
            </v-app-bar>
          </v-col>
        </v-row>
      </section>
      <router-view />
      <BottomFooter />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: { BottomFooter: () => import('../src/components/BottomFooter.vue') },
  data: () => ({
    drawer: false,
    tradeSubtitles: [
      { title: '金属加工', to: '/metalProcessing' },
      { title: '汽配', to: '/autoParts' },
      { title: '印刷', to: '/printing' },
      { title: '新能源', to: '/newEnergy' }
    ],
    newsSubtitles: [
      { title: '集团资讯', to: '/companyInformation' },
      { title: '行业资讯', to: '/tradeInformation' }
    ]
  })
}
</script>
