<template>
  <section style="background-color:#f4f8fa;padding-bottom:15vh">
    <AltPicPt :altText="altText" :altText_="altText_" />
    <v-container fluid>
      <v-row class="mx-auto">
        <v-col cols="12" md="4" data-aos="zoom-in-up" data-aos-duration="1500">
          <v-hover v-slot="{ hover }">
            <v-card :elevation="hover ? 24 : items[0].elevation" :class="hover ? 'zoom' : 'notzoom'" class="mx-md-16  transition-swing card" rounded="xl" @click="goToMES">
              <h3 class="text-h4 text-center font-weight-black white--text pt-5" v-text="items[0].plan"></h3>
              <v-card-text class="text-center subtitle-1 white--text py-2 mb-12" v-text="items[0].description"></v-card-text>
              <v-card>
                <v-img src="../assets/home/MES.png" lazy-src="../assets/home/MES.png" style="height:50vh">
                  <div class=" d-flex align-end mx-12" style="height:95%">
                    <v-btn style='background:linear-gradient(to bottom right, #33cc, #3399cc);' large block rounded dark class="subtitle-1">
                      更多
                    </v-btn>
                  </div>
                </v-img>
              </v-card>
            </v-card>
          </v-hover>
        </v-col>
        <v-col cols="12" md="4" data-aos="zoom-in-up" data-aos-duration="1500">
          <v-hover v-slot="{ hover }">
            <v-card :elevation="hover ? 24 : items[1].elevation" :class="hover ? 'zoom' : 'notzoom'" class="mx-md-16  transition-swing card" rounded="xl" @click="goToWCS">
              <h3 class="text-h4 text-center font-weight-black white--text pt-5" v-text="items[1].plan"></h3>
              <v-card-text class="text-center subtitle-1 white--text py-2 mb-12" v-text="items[1].description"></v-card-text>
              <v-card>
                <v-img src="../assets/home/WCS.png" lazy-src="../assets/home/WCS.png" style="height:50vh">
                  <div class=" d-flex align-end mx-12" style="height:95%">
                    <v-btn style='background:linear-gradient(to bottom right, #33cc, #3399cc);' large block rounded dark class="subtitle-1">
                      更多
                    </v-btn>
                  </div>
                </v-img>
              </v-card>
            </v-card>
          </v-hover>
        </v-col>
        <v-col cols="12" md="4" data-aos="zoom-in-up" data-aos-duration="1500">
          <v-hover v-slot="{ hover }">
            <v-card :elevation="hover ? 24 : items[2].elevation" :class="hover ? 'zoom' : 'notzoom'" class="mx-md-16 x-md  transition-swing card" rounded="xl" @click="goToWMS">
              <h3 class="text-h4 text-center font-weight-black white--text pt-5" v-text="items[2].plan"></h3>
              <v-card-text class="text-center subtitle-1 white--text py-2 mb-12" v-text="items[2].description"></v-card-text>
              <v-card>
                <v-img src="../assets/home/WMS.png" lazy-src="../assets/home/WMS.png" style="height:50vh">
                  <div class=" d-flex align-end mx-12" style="height:95%">
                    <v-btn style="background:linear-gradient(to bottom right, #33cc, #3399cc);" large block rounded dark class="subtitle-1">
                      更多
                    </v-btn>
                  </div>
                </v-img>
              </v-card>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import AltPicPt from './AltPicPt.vue'
export default {
  components: { AltPicPt },
  data () {
    return {
      altText: '我们的产品',
      altText_: 'our product',
      items: [
        {
          plan: 'MES',
          elevation: 3,
          description: '智能制造执行系统'
        },
        {
          plan: 'WCS',
          elevation: 16,
          description: '智能仓库控制系统'
        },
        {
          plan: 'WMS',
          elevation: 3,
          description: '智能仓库管理系统'
        }
      ]
    }
  },
  methods: {
    goToMES () {
      localStorage.setItem('productLocation', 'page-mes')
      this.$router.push('/product')
    },
    goToWCS () {
      localStorage.setItem('productLocation', 'page-wcs')
      this.$router.push('/product')
    },
    goToWMS () {
      localStorage.setItem('productLocation', 'page-wms')
      this.$router.push('/product')
    }
  }
}
</script>

<style scoped>
.zoom {
  transform: scale(1.025) translate(0, -10px);
  transition: transform 0.2s;
}
.notzoom {
  transition: transform 0.2s;
}
.card {
  background: linear-gradient(to bottom right, #33cc, #3399cc);
}
</style>
