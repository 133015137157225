import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import vuetify from './plugins/vuetify'
import AOS from 'aos'
import 'aos/dist/aos.css'
import AmapVue from '@amap/amap-vue'
Vue.config.productionTip = false
Vue.prototype.$axios = axios
// 设置统一的axios请求路径
axios.defaults.baseURL = JSON.parse(sessionStorage.getItem('resUrl')).path
AmapVue.config.version = '2.0' // 默认2.0，这里可以不修改
AmapVue.config.key = '2998218be64a41dbe9318a495446e43e'
AmapVue.config.plugins = [
  'AMap.ToolBar',
  'AMap.MoveAnimation'
  // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
]
Vue.use(AmapVue)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  mounted () {
    AOS.init()
  }
}).$mount('#app')
