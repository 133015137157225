<template>
  <section>
    <v-carousel :height="$vuetify.breakpoint.mobile? '350px':'calc(100vh - 64px)'" dark cycle show-arrows-on-hover hide-delimiter-background interval="18000">
      <v-carousel-item v-for="(carousel, carindex) in carouselsData" :key="carindex" :src="carousel.src" class="gradient-fill" eager>
        <!-- <v-container fill-height>
          <div style="max-width: 800px">
            <div class="text-md-h3 mb-3 text-sm-h3 text-h5 font-weight-black white--text" style="color:#3399cc">
              {{ carousel.heading.toUpperCase() }}
            </div>
            <div class="text-md-h3 mb-3 text-sm-h3 text-h5 font-weight-black white--text" style="color:#3399cc">
              {{ carousel.heading_.toUpperCase() }}
            </div>
            <p class="mb-5 white--text">
              {{ carousel.subHeading }}
            </p>
            <v-btn :x-large="$vuetify.breakpoint.smAndUp" class="my-3" color="primary" dark>开始</v-btn>
            <span class="mx-2 my-4"></span>
            <v-btn :x-large="$vuetify.breakpoint.smAndUp" class="my-3" color="primary" dark>
              <v-icon left large>mdi-play</v-icon>
              更多
            </v-btn>
          </div>
        </v-container> -->
      </v-carousel-item>
    </v-carousel>
  </section>
</template>

<script>
export default {
  data () {
    return {
      carouselsData: [
        {
          src: require('../assets/home/轮播图1-min.gif'),
          heading: '技术领先的',
          heading_: '高端智能工厂信息技术集成服务商',
          subHeading: '云联旨在工业软件领域发展、增强同日集团的核心竞争力，通过多平台软件，协助客户打通信息孤岛，助力工厂实现智能。'
        },
        {
          src: require('../assets/home/轮播图2-min.gif'),
          heading: '智能物流',
          heading_: '整体解决方案制造升级|数字转型',
          subHeading: '云联旨在工业软件领域发展、增强同日集团的核心竞争力，通过多平台软件，协助客户打通信息孤岛，助力工厂实现智能。'
        },

        {
          src: require('../assets/home/轮播图3-min.gif'),
          heading: '基于',
          heading_: '工业互联网标识解析的PLM',
          subHeading: '云联旨在工业软件领域发展、增强同日集团的核心竞争力，通过多平台软件，协助客户打通信息孤岛，助力工厂实现智能。'
        }
      ]
    }
  }
}
</script>

<style>
/* .gradient-fill .v-responsive__content {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    to right,
    rgba(105, 140, 204, 0.4),
    rgba(89, 105, 155, 0.4)
  );
} */
</style>
