<template>
  <section style="padding-bottom:15vh">
    <AltPicPt :altText="altText" :altText_="altText_" />
    <v-row no-gutters>
      <v-col cols="12">
        <v-row no-gutters data-aos="fade-right" data-aos-duration="2000">
          <v-col cols="12" md="6" align-self="center">
            <v-img max-height="800" src="../assets/home/工业4.0.jpg" lazy-src="../assets/home/工业4.0.jpg" class="rounded-xl">
            </v-img>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <div class="pa-lg-16 pa-md-10 px-4 py-16">
              <h2 class="text-h4 text-center font-weight-black">基于工业4.0</h2>
              <h3 class="text-h5 text-uppercase font-weight-thin text-center my-8">
                以智能制造为主导的“智能工厂”,“智能生产”
              </h3>
              <p style="font-size:17px; letter-spacing: 2px" class="font-weight-thin">
                同日云联致力于“智能工厂”,“智能生产”,重点研究智能化生产系统及过程,以及网络化分布式生产设施的实现,主要涉及整个企业的生产物流管理、人机互动以及3D技术在工业生产过程中的应用等。<br>未来，传统的制造企业都将向“智慧工厂”转型，用自动化和机械设备代替人工，加工制造过程完全靠设备完成，这些设备还可以互联，人员、材料、设备、成品半成品实现管控一体化。机器人及智能制造成套装备是“数字化工厂”基本构成，机器人的使用不仅仅是简单的替代人工作业，更重要的是集成“信息化”和“工业化”，改变传统的制造模式。
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="flex-row-reverse mt-16" data-aos="fade-left" data-aos-duration="2000">
          <v-col cols="12" md="6" align-self="center">
            <v-img max-height="800" src="../assets/home/标识解析.png" lazy-src="../assets/home/标识解析.png">
            </v-img>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <div class="pa-lg-16 pa-md-10 px-4 py-16">
              <h2 class="text-h4 text-center font-weight-black">基于标识解析</h2>
              <h3 class="text-h5 text-uppercase font-weight-thin text-center my-8">
                工业互联网标识解析系统
              </h3>
              <p style="font-size:17px; letter-spacing: 2px" class="font-weight-thin">
                昆山同日云联是标识解析综合行业二级节点建设单位之一，已实现与国家顶级节点的对接，开展业务上线；通过二级节点标识注册和解析业务，实现对物品赋予唯一防篡可信的“身份证”—标识码，实现产品全生命周期追溯和防伪认证的过程精细化管理。<br>同日云联可提供：<br><strong>·</strong> 企业标识申请注册的接入和管理服务 <br><strong>·</strong> 专用标识的设计、制作和供应 <br><strong>·</strong> 基于标识解析系统的应用软件方案
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-16" data-aos="fade-right" data-aos-duration="2000">
          <v-col cols="12" md="6" align-self="center">
            <v-img max-height="800" src="../assets/home/TRX产品优势.jpg" lazy-src="../assets/home/TRX产品优势.jpg" class="rounded-xl">
            </v-img>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <div class="pa-lg-16 pa-md-10 px-4 py-16">
              <h2 class="text-h4 text-center font-weight-black">产品竞争力</h2>
              <h3 class="text-h5 text-uppercase font-weight-thin text-center my-8">
                综合物流管理平台
              </h3>
              <p style="font-size:17px; letter-spacing: 2px" class="font-weight-thin">
                同日云联综合物流管理平台(TRX)是同日公司在积累了十几年物流行业经验的基础上研发、提炼而来的一个软件平台，平台的特色是，对离散制造业的车间物流设备软硬件无缝结合系统包含生产执行系统(MES)、仓库管理系统(WMS)仓库控制系统(WCS)、生产控制中心(PCC)等子系统。各子系统通过接口管理平台(IMS)实现相互紧密合作，实时互通，资源共享。管理者可通过PC端、移动端实时查看订单状况、生产执行状况、产品质量状况、库存需缺状况、人工成本状况等;基于统计数据，导入TRX产品后，可帮助企业缩短周期，保证品质，降低成本，预计可提升运营效率15%左右。我们不仅仅提供信息化技术和系统，最重要的是我们提供的是世界级的工厂制造解决方案! </p>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="flex-row-reverse mt-16" data-aos="fade-left" data-aos-duration="2000">
          <v-col cols="12" md="6" align-self="center">
            <v-img max-height="800" src="../assets/home/TRX服务保障.jpg" lazy-src="../assets/home/TRX服务保障.jpg" class="rounded-xl">
            </v-img>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <div class="pa-lg-16 pa-md-10 px-4 py-16">
              <h2 class="text-h4 text-center font-weight-black">服务保障</h2>
              <h3 class="text-h5 text-uppercase font-weight-thin text-center my-8">
                专业化服务、品质保证、全程无忧
              </h3>
              <p style="font-size:17px; letter-spacing: 2px" class="font-weight-thin">
                企业信息化建设是一项复杂的工程，它不仅取决于成熟可靠的软件产品，还依赖于各种专业化的服务作为支撑和保障。同日云联以多年来丰富的行业积累和项目经验为依托，有信心和能力为企业信息化建设提供专业化的服务保障。主要包括：<br>生产陪产服务：系统上线后，由专业服务团队陪同跟进生产过程1-3个月，负责日常系统运行问题的解决及维护工作；<br>免费售后维护：系统交付后，我们提供1年的免费售后维护服务，包括系统升级、异常问题处理等；<br>售后服务响应：7×24小时电话服务，确保及时响应，保障客户系统的稳定运行；<br>服务技术支持：通过远程监控、在线诊断、现场服务等多种方式，为客户提供及时帮助。
              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import AltPicPt from './AltPicPt.vue'
export default {
  components: { AltPicPt },
  data () {
    return {
      altText: '我们的优势',
      altText_: 'OUR ADVANTAGE'
    }
  },
  mounted () {

  }
}
</script>
<style scoped>
