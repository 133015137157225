<template>
  <section style="background-color:#f4f8fa;padding-bottom:15vh">
    <AltPicPt :altText="altText" :altText_="altText_" />
    <v-container fluid>
      <v-row>
        <v-col cols="12" md='4' v-for="(item,index) in newsData" :key="index" data-aos="flip-left" data-aos-duration="2000">
          <v-hover v-slot="{ hover }">
            <v-card class="mx-md-16 mx-4" color="grey lighten-4" max-width="600" style="height:60vh" @click="goToDetail(item.index)">
              <v-img :aspect-ratio="16/9" :src="item.pic" :lazy-src="item.pic" style="height:35vh">
                <v-expand-transition>
                  <div v-if="hover" class="d-flex transition-fast-in-fast-out blue v-card--reveal text-h4 white--text" style="height: 100%">
                    查看详情
                  </div>
                </v-expand-transition>
              </v-img>
              <v-card-text class="pt-6" style="position: relative;">
                <v-btn absolute color="blue" class="white--text" fab large right top>
                  <v-icon>mdi-email</v-icon>
                </v-btn>
                <h3 class="text-md-h5 text-h6 font-weight-light mx-6 text-center" style="padding-top:5vh">
                  {{item.title}}
                </h3>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </section>

</template>

<script>
import AltPicPt from './AltPicPt.vue'
export default {
  components: { AltPicPt },
  data () {
    return {
      altText: '最新资讯',
      altText_: 'recent news',
      newsData: [
        {
          title: ' 昆山同日荣获苏州市智能化改造和数字化转型优秀服务商',
          pic: require('../assets/information/20210707.jpg'),
          index: 0
        },
        {
          title: '同日工业互联网标识解析综合行业二级节点上线',
          pic: require('../assets/information/卡片图片1.jpg'),
          index: 5
        },
        {
          title: '航天九院、航天十三所领导一行莅临公司参观考察',
          pic: require('../assets/information/201903.jpg'),
          index: 8
        }
      ]
    }
  },
  methods: {
    goToDetail (index) {
      localStorage.setItem('newsIndex', index)
      this.$router.push('/detailInformation')
    }
  }
}
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.6;
  position: absolute;
  width: 100%;
}
</style>
