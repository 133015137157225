<template>
  <section>
    <AltPicPt :altText="altText" :altText_="altText_" />
    <v-container>
      <v-timeline style="margin-bottom:10vh" :dense="$vuetify.breakpoint.xsOnly">
        <v-timeline-item v-for="(item,index) in items" :key="index" large data-aos="zoom-in" data-aos-duration="2000">
          <template v-slot:icon>
            <v-avatar>
              <v-img :src="require('../assets/home/时间轴logo.jpg')" :lazy-src="require('../assets/home/时间轴logo.jpg')"></v-img>
            </v-avatar>
          </template>
          <template v-slot:opposite>
            <span></span>
          </template>

          <v-card class="elevation-2" @click="$router.push('/about')">
            <v-img :src="item.pic" :lazy-src="item.pic" max-height="300px" dark gradient="to top right, rgba(85, 96, 133, 0.4), rgba(85, 96, 133, 0.4)">
              <div class="text-md-h3 text-h4 text-center mt-md-16 mt-6">
                {{item.year}}
              </div>
              <v-card-text class="text-md-h6 text-subtitle-1 text-center">{{item.text}}</v-card-text>
            </v-img>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-container>
  </section>
</template>

<script>
import AltPicPt from './AltPicPt.vue'
export default {
  components: { AltPicPt },
  data () {
    return {
      altText: '关于我们',
      altText_: 'about us',
      items: [
        {
          year: '2010',
          text: '成立昆山同日工业自动化有限公司，专注于工厂仓促物流',
          pic: require('../assets/home/时间轴背景图-min.jpg')
        },
        {
          year: '2015',
          text: '转型为智能制造、智能物流整体方案集成商',
          pic: require('../assets/home/时间轴背景图-min.jpg')
        },
        {
          year: '2016',
          text: '设立软件部，专注于工业软件的开发与实施',
          pic: require('../assets/home/时间轴背景图-min.jpg')
        },
        {
          year: '2018',
          text: '建设省级工业互联网平台和同日私有云，成为中国软件联盟副理事长单位',
          pic: require('../assets/home/时间轴背景图-min.jpg')
        },
        {
          year: '2019',
          text: '成为国家知识产权示范企业，双创平台试点示范企业',
          pic: require('../assets/home/时间轴背景图-min.jpg')
        },
        {
          year: '2020',
          text: '成为工业互联网标识解析综合行业二级节点建设单位',
          pic: require('../assets/home/时间轴背景图-min.jpg')
        },
        {
          year: '2021',
          text: '昆山同日设立同日云联信息技术（苏州）有限公司',
          pic: require('../assets/home/时间轴背景图-min.jpg')
        },
        {
          year: '2022',
          text: '在WIC世界智能大会荣获TOP10',
          pic: require('../assets/home/wic时间轴.jpg')
        }
      ]
    }
  },
  created: function () {
    this.items.reverse()
  }
}
</script>
