<template>
  <div class="pt-16">
    <v-img height="30vh" :src="picArr[picIndex]" :lazy-src="picArr[picIndex]">
      <v-container fill-height>
        <v-row>
          <v-col>
            <div class="text-h3 text-center font-weight-black" style="color: #666666;">
              {{ altText }}
            </div>
            <div class="text-h4 mt-2 text-center text-uppercase font-weight-black" style=" color: #5585b4;">
              {{altText_}}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </div>
</template>

<script>
export default {
  props: ['altText', 'altText_', 'picIndex'],
  data: () => ({
    picArr: [
    ]
  })
}
</script>
