<template>
  <div>
    <!-- v-expand-transition包裹的元素在渲染到页面或移除页面时会有展开动画 -->
    <v-expand-transition>
      <div style="width:100%;height:100%;z-index:999;position:fixed" class="body" v-if="isLoading">
        <div class="ring">
          <strong>TRLINK</strong>
          <span></span>
        </div>
      </div>
    </v-expand-transition>
    <Carousel />
    <ProductCard />
    <Advantage />
    <News />
    <Timeline />
  </div>
</template>

<script>
import Carousel from '../components/Carousel.vue'
import ProductCard from '../components/ProductCard.vue'
import Advantage from '../components/Advantage.vue'
import Timeline from '../components/Timeline.vue'
import News from '../components/News.vue'
export default {
  components: { Carousel, ProductCard, Advantage, Timeline, News },
  props: [],
  data: function () {
    return {
      altText1: '我们的产品',
      altText_1: 'OUR PRODUCT',
      altText2: '我们的优势',
      altText_2: 'OUR ADVANTAGE',
      altText3: '最新资讯',
      altText_3: 'OUR NEWS',
      altText4: '关于我们',
      altText_4: 'ABOUT US',
      picIndex: -1,
      isLoading: true
    }
  },
  mounted () {
    setTimeout(() => {
      this.isLoading = false
    }, 1000)
  }
}
</script>
<style scoped>
.body {
  margin: 0;
  padding: 0;
  background: #262626;
}

.ring {
  position: absolute;
  top: 50%;
  letter-spacing: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  background: transparent;
  border: 4px solid #551717;
  border-radius: 50%;
  text-align: center;
  line-height: 210px;
  font-family: sans-serif;
  font-size: 25px;
  color: #40b7f3;
  /* 字间距 */
  letter-spacing: 4px;
  /* 自填变为大写 */
  text-transform: uppercase;
  text-shadow: 0 0 10px #40b7f3;
  box-shadow: 0 0 20px rgba(148, 148, 148, 0.5);
}

.ring::before {
  content: " ";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 4px solid transparent;
  border-top: 4px solid;
  border-right: 4px solid;
  border-radius: 50%;
  animation: animateCircle 2s linear infinite;
}

span {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: animate 2s linear infinite;
}

span::before {
  content: " ";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #40b7f3;
  top: -10px;
  right: -12px;
  box-shadow: 0 0 20px #40b7f3;
}

@keyframes animateCircle {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}
</style>
